body {
  margin: 0;
  font-family: "Montserrat", "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  color: #00233b;
}

.sub-title {
  font-size: 19px;
  font-weight: 600;
  color: #00233b;
}

.card-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 17px;
}

.sub-info {
  font-family: "Open Sans";
  color: #596975;
  font-size: 14px;
}

.sub-info-bold {
  font-family: "Open Sans";
  color: #596975;
  font-weight: 600;
  font-size: 14px;
}

.sub-info-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 15px;
  color: #005baa;
  padding-top: 5px;
}

.date-box {
  width: 48px;
  height: 48px;
  background-color: #edf7fd;
  border-radius: 4px;
}

.date-box > .month {
  font-family: "Open Sans";
  font-size: 14px;
  color: #0094b3;
  line-height: 1.4;
}

.date-box > .date {
  color: #0094b3;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
}

.date-title {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  color: #00233b;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.blue-button {
  font-family: "Open Sans";
  background-color: #005baa;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  padding: 13px;
  width: 100%;
  border-width: 0;
  border-radius: 5px;
}

.blue-button:hover {
  color: #fff;
}

.grey-outline-button {
  font-family: "Open Sans";
  border: 1px solid #dbddde;
  background-color: #ffffff;
  color: #596975;
  font-weight: 600;
  font-size: 14px;
  padding: 13px;
  border-style: solid;
  border-radius: 5px;
}

.contact-us {
  font-weight: 600;
  font-size: 15px;
  color: #00233b;
}

.accordion .title {
  font-size: 24px;
  font-weight: 600;
  color: #00233b;
  padding: 10px 12px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  position: relative;
  background: #f2f2f2;
  margin-bottom: -1px;
  /* border-bottom: 1px solid #0e4671; */
  text-align: left;
}
.accordion .title::after {
  content: "+";
  font-size: 24px;
  color: #00233b;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  right: 30px;
  font-family: monospace;
}

.accordion .title::after {
  content: "-";
  transform: rotate(-360deg);
}

/* .accordion .collapse {
  overflow: hidden;
  max-height: 0;
  transition: max-height .5s;
  margin: 0;
} */

.accordion .show {
  display: block;
}

.label-title {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  color: #00233b;
}

.asterisk {
  color: red;
}

.form-control {
  font-family: "Open Sans";
}

.add-purchaser {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  color: #49b3b1;
  background-color: #ffffff;
  border-width: 0;
  border-radius: 5px;
  padding: 0;
}

.notify-container {
  background-color: #edf7fd;
  border-radius: 4px;
  padding: 12px;
}

.notify {
  font-family: "Open Sans";
  font-size: 12px;
  color: #596975;
}

.upload-image-container {
  background: #f4f5f7;
  border: 1px dashed #969ca4;
  box-sizing: border-box;
  border-radius: 7px;
  height: 55px;
}

.upload-image {
  font-family: "Open Sans";
  color: #49b3b1;
  font-weight: 600;
  font-size: 12px;
}

.upload-image-uploaded {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00233b;
}

.intl-tel-input {
  display: block !important;
  font-family: "Open Sans";
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 0 !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex !important;
}

.iti-flag {
  margin-right: 5px;
}

.react-datepicker-wrapper {
  display: block !important;
}

img.calendar {
  position: absolute;
  top: 10px;
  right: 14px;
}

.detail-link {
  padding: 13px;
  border-bottom: 2px solid #f4f5f7;
}

.detail-link a,
.detail-link div {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #596975;
}

.detail-link.active {
  border-bottom: 2px solid #005baa !important;
}

.detail-link.active a {
  color: #005baa !important;
}

.sub-info-small {
  font-family: "Open Sans";
  font-size: 12px;
  color: #969ca4;
}

.font10 {
  font-family: "Open Sans";
  color: #596975;
  font-size: 10px;
}

.time {
  font-size: 24px;
  color: #596975;
}

.layout-title {
  font-family: "Open Sans";
  font-size: 14px;
  color: #00233b;
}

.background-grey {
  margin-top: 10px;
  background-color: #f8f9f9;
  padding: 15px 25px 15px 15px;
}

.blue-link {
  font-family: "Open Sans";
  font-size: 14px;
  color: #005baa;
}

.table-spacing {
  border-collapse: separate;
  border-spacing: 0 7px;
}

.prev {
  left: -10px;
}

.next {
  right: -10px;
}

.prev,
.next {
  position: absolute;
  top: -10px;
  display: block;
  z-index: 1;
  padding: 0 7px;
  font-size: 12px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  cursor: pointer;
}

.image-counter {
  font-family: "Open Sans";
  position: absolute;
  left: 14px;
  display: block;
  z-index: 1;
  padding: 0 7px;
  font-size: 10px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.document-name {
  font-family: "Open Sans";
  font-size: 12px;
  color: #49b3b1;
}

@media only screen and (max-width: 800px) {
  .background-grey {
    margin-top: 0;
  }
  /* Force table to not be like tables anymore */
  #table-box table,
  #table-box thead,
  #table-box tbody,
  #table-box th,
  #table-box td,
  #table-box tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #table-box thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #table-box tr {
    margin-top: 30px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  }

  #table-box td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    white-space: normal;
    text-align: left;
  }

  #table-box td:first-child {
    border-top: 2px solid #eee;
  }

  #table-box td:last-child {
    border-bottom: none;
  }

  #table-box td:before {
    font-weight: normal;
    content: attr(data-label);
    padding-right: 10px;
    text-transform: capitalize;
    display: block;
    width: 100%;
  }

  /*
  Label the data
  */
  #table-box td:before {
    content: attr(data-title);
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 14px;
    color: #00233b;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-purchase {
  text-align: left;
  color: #00233b;
  background-color: #f2f2f2;
  width: 100%;
  padding: 10px 12px;
}

.intl-tel-input input::placeholder {
  color: #969ca4;
}

.form-control {
  font-size: 0.875rem;
}

.virtual-tour {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #005baa;
}

.dropdown button::after {
  color: #005baa;
}

.dropdown-title {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  color: #005baa;
}

.dropdown-option {
  font-family: Open Sans;
  font-size: 14px;
}

.dropdown > .btn-link:hover,
.dropdown > .btn-link:focus {
  text-decoration: none;
}

.booking-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #00233b;
}

.booking-card {
  background: #ffffff;
  box-shadow: 0px 2px 6px #e7eaed;
  border-radius: 4px;
}
.booking-card-header {
  padding: 15px;
  border-bottom: 1px solid #e7eaed;
}

.booking-card-header .title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  color: #00233b;
}
.booking-card-header .sub-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00233b;
}

.booking-card-body {
  padding: 15px;
}

.booking-card-body .title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #596975;
}

.booking-card-body .info {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #00233b;
}
.booking-status {
  border-radius: 20px;
  padding: 3px 10px;
  border: 1px solid #959ca4;
}

.booking-status span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: "#596975";
}

.booking-success {
  border: 1px solid #50b793;
}

.booking-success span {
  color: #50b793;
}

.booking-warning {
  border: 1px solid #f2b42a;
}

.booking-warning span {
  color: #f2b42a;
}

.booking-danger {
  border: 1px solid #ff796e;
}

.booking-danger span {
  color: #ff796e;
}

.tab {
  overflow-x: scroll;
  -ms-overflow-style: none;
}

.tab::-webkit-scrollbar {
  display: none;
}

@media (max-width: 575.98px) {
  .detail-link {
    padding-left: 8px;
    padding-right: 6px;
  }
}
.dropdown-doc-option {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #00233b;
}

@media (min-width: 768px){
  .pl-special {
    padding-left: 0.5rem!important;
}
}
